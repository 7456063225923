import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import AuthContext from '../contexts/AuthContext';
import config from 'config';

const ChatPage = () => {
    const [messages, setMessages] = useState([]);
    const [chatFiles, setChatFiles] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [file, setFile] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const { chatId } = useParams();
    const { user } = useContext(AuthContext);

    useEffect(() => {
        fetchChatHistory();
        fetchChatFiles();
    }, [chatId]);

    const fetchChatHistory = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${config.apiUrl}/chat/${chatId}/messages`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setMessages(response.data);
        } catch (error) {
            console.error('Failed to fetch chat history', error);
        }
    };

    const fetchChatFiles = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${config.apiUrl}/chat/${chatId}/files`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setChatFiles(response.data);
        } catch (error) {
            console.error('Failed to fetch chat files', error);
        }
    };

    const sendMessage = async () => {
        try {
            setIsProcessing(true);
            const token = localStorage.getItem('token');
            const formData = new FormData();
            if (newMessage) {
                formData.append('content', newMessage);
            }
            if (file) {
                formData.append('file', file);
            }

            const response = await axios.post(
                `${config.apiUrl}/chat/${chatId}/messages`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            // Add both user and bot messages to the chat
            setMessages(prevMessages => [...prevMessages, ...response.data]);

            // Check if PDF processing is needed
            const botMessage = response.data[1]; // The bot message is the second item in the array
            if (file && file.name.toLowerCase().endsWith('.pdf')) {
                pollForBotResponse(botMessage.id);
            } else {
                setIsProcessing(false);
            }

            setNewMessage('');
            setFile(null);
            fetchChatFiles();  // Refresh the file list after sending a new message
        } catch (error) {
            console.error('Failed to send message', error);
            setIsProcessing(false);
        }
    };

    const pollForBotResponse = async (messageId) => {
        const token = localStorage.getItem('token');
        let pollCount = 0;
        const maxPolls = 300; // Stop polling after 50 minutes (300 * 10 seconds)

        const pollInterval = setInterval(async () => {
            try {
                pollCount++;
                console.log(`Polling attempt ${pollCount} for message ${messageId}`);

                const response = await axios.get(`${config.apiUrl}/chat/messages/${messageId}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                
                console.log(`Received response for message ${messageId}:`, response.data);

                if (response.data.processing_status === 'COMPLETED' || response.data.processing_status === 'FAILED') {
                    console.log(`Processing completed for message ${messageId} with status: ${response.data.processing_status}`);
                    clearInterval(pollInterval);
                    setMessages(prevMessages => {
                        const updatedMessages = [...prevMessages];
                        const messageIndex = updatedMessages.findIndex(msg => msg.id === response.data.id);
                        if (messageIndex !== -1) {
                            updatedMessages[messageIndex] = response.data;
                        } else {
                            updatedMessages.push(response.data);
                        }
                        return updatedMessages;
                    });
                    setIsProcessing(false);
                } else if (pollCount >= maxPolls) {
                    console.log(`Polling timeout reached for message ${messageId}`);
                    clearInterval(pollInterval);
                    setIsProcessing(false);
                    // Optionally, update the message to indicate a timeout
                    setMessages(prevMessages => 
                        prevMessages.map(msg => 
                            msg.id === messageId 
                                ? {...msg, content: msg.content + "\n(Processing timed out)", processing_status: 'FAILED'}
                                : msg
                        )
                    );
                }
            } catch (error) {
                console.error(`Error polling for message ${messageId}:`, error);
                if (pollCount >= maxPolls) {
                    clearInterval(pollInterval);
                    setIsProcessing(false);
                    // Optionally, update the message to indicate an error
                    setMessages(prevMessages => 
                        prevMessages.map(msg => 
                            msg.id === messageId 
                                ? {...msg, content: msg.content + "\n(Error occurred during processing)", processing_status: 'FAILED'}
                                : msg
                        )
                    );
                }
            }
        }, 10000); // Poll every 10 seconds
    };


    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleFileDownload = (download_url, filename) => {
        window.open(download_url, '_blank');
    };

    // Helper function to render message content with line breaks and bold labels
    const renderMessageContent = (content) => {
        return content.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line.split(/(\*\*.*?\*\*)/).map((part, partIndex) => {
                    if (part.startsWith('**') && part.endsWith('**')) {
                        return <strong key={partIndex}>{part.slice(2, -2)}</strong>;
                    }
                    return part;
                })}
                <br />
            </React.Fragment>
        ));
    };

    return (
        <div>
            <h2>Chat {chatId}</h2>
            <div style={{
                display: 'flex',
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                padding: '10px',
                border: '1px solid #ccc',
                marginBottom: '10px'
            }}>
                {chatFiles.map((file) => (
                    <div key={file.id} style={{
                        margin: '0 10px',
                        padding: '5px',
                        border: '1px solid #ddd',
                        borderRadius: '5px',
                        display: 'inline-block'
                    }}>
                        <p style={{ margin: '0 0 5px 0', fontSize: '0.9em' }}>{file.filename}</p>
                        <button 
                            onClick={() => handleFileDownload(file.download_url, file.filename)}
                            style={{
                                fontSize: '0.8em',
                                padding: '2px 5px',
                                backgroundColor: '#f0f0f0',
                                border: '1px solid #ccc',
                                borderRadius: '3px',
                                cursor: 'pointer'
                            }}
                        >
                            Download
                        </button>
                    </div>
                ))}
            </div>
            <div style={{height: '400px', overflowY: 'scroll', border: '1px solid #ccc', padding: '10px', marginBottom: '20px'}}>
                {messages.map((message, index) => (
                    <div key={index} style={{
                        marginBottom: '10px',
                        textAlign: message.is_from_user ? 'right' : 'left'
                    }}>
                        <p style={{
                            display: 'inline-block',
                            padding: '8px 12px',
                            borderRadius: '12px',
                            backgroundColor: message.is_from_user ? '#dcf8c6' : '#f1f0f0',
                            maxWidth: '70%',
                            whiteSpace: 'pre-wrap',
                            wordWrap: 'break-word'
                        }}>
                            {renderMessageContent(message.content)}
                            {message.processing_status === 'PROCESSING' && (
                                <span style={{ fontStyle: 'italic', fontSize: '0.8em' }}> (Processing...)</span>
                            )}
                        </p>
                        {message.files && message.files.length > 0 && (
                            <p>File: {message.files[0].filename}</p>
                        )}
                    </div>
                ))}
            </div>
            <textarea 
                value={newMessage} 
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type a message"
                style={{
                    width: '100%',
                    minHeight: '60px',
                    resize: 'vertical'
                }}
            />
            <input 
                type="file" 
                onChange={handleFileChange}
                disabled={isProcessing}
            />
            <button onClick={sendMessage} disabled={isProcessing}>
                {isProcessing ? 'Processing...' : 'Send'}
            </button>
        </div>
    );
};

export default ChatPage;