import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AuthContext from '../contexts/AuthContext';
import config from 'config';

const ChatsList = () => {
    const [chats, setChats] = useState([]);
    const { user } = useContext(AuthContext);

    useEffect(() => {
        const fetchChats = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${config.apiUrl}/chat/`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setChats(response.data);
            } catch (error) {
                console.error('Failed to fetch chats', error);
            }
        };

        fetchChats();
    }, []);

    const createNewChat = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(`${config.apiUrl}/chat/`, {}, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setChats([...chats, response.data]);
        } catch (error) {
            console.error('Failed to create chat', error);
        }
    };

    return (
        <div>
            <h2>Your Chats</h2>
            <button onClick={createNewChat}>Create New Chat</button>
            <ul>
                {chats.map(chat => (
                    <li key={chat.chat_id}>
                        <Link to={`/chat/${chat.chat_id}`}>
                            Chat ID: {chat.chat_id}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ChatsList;
