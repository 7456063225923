// src/config.js

const dev = {
  apiUrl: "http://localhost:8000",
};

const prod = {
  apiUrl: "https://inquery-demo.com/api",
};

const config = process.env.REACT_APP_STAGE === 'production'
  ? prod
  : dev;

export default {
  ...config
};
